import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useUser } from "../context/UserContext";
import axios from "./api/axios";

function ReviewPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const [snapshotData, setSnapshotData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [feedback, setFeedback] = useState("neutral");
  const [reviewLength, setReviewLength] = useState(0);

  const { user, setUser, token } = useUser(); //
  console.log("User context in ReviewPage:", user); // Add this line

  //ADD Profile Fetch code here
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        console.log("Token used for request:", token);
        const response = await axios.get("/api/user/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          console.log("Fetched user profile:", response.data); // Debug: Log fetched user data

          setUser(response.data);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    if (!user) {
      fetchUserProfile();
    }
  }, [user, setUser, token]);

  //End Here

  useEffect(() => {
    if (
      snapshotData &&
      snapshotData.legal_name &&
      snapshotData.mc_mx_ff_numbers
    ) {
      const nameInput = document.getElementsByName("name")[0];
      const numberInput = document.getElementsByName("number")[0];
      if (nameInput && numberInput) {
        nameInput.value = snapshotData.legal_name || "";
        const trimmedMCNumber =
          snapshotData.mc_mx_ff_numbers.substring(3) || "";
        numberInput.value = trimmedMCNumber;
      }
    }
  }, [snapshotData]);

  const handleSearch = () => {
    if (!searchQuery) {
      return;
    }

    setLoading(true);
    setError(null);

    fetch(`https://saferwebapi.com/v2/mcmx/snapshot/${searchQuery}`, {
      headers: {
        "x-api-key": "452a89b7d7da48fcb77677e2e6b2481e",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Broker not found. Please revise MC number.");
        }
        return response.json();
      })
      .then((data) => {
        setSnapshotData(data);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleReviewChange = (event) => {
    setReviewLength(event.target.value.length);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!user) {
      console.error("User information is missing.");
      setShowErrorModal(true);
      return;
    }
    const formData = {
      name: event.target.name.value,
      number: event.target.number.value,
      reviews: [
        {
          username: user.user,
          review: event.target.review.value,
          dateStamp: `${new Date().toISOString().split("T")[0]} ${new Date()
            .getHours()
            .toString()
            .padStart(2, "0")}:${new Date()
            .getMinutes()
            .toString()
            .padStart(2, "0")}`,
          feedback: feedback,
        },
      ],
    };

    try {
      setLoading(true);
      const response = await fetch(
        "https://www.thebroker411.com:8443/api/Brokers",
        {
          method: "POST", //FIX This
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const responseBody = await response.text(); // Read the response body as text
      console.log("Response body:", responseBody);

      if (response.ok) {
        console.log("Review submitted successfully!");
        setShowSuccessModal(true);
        setSearchQuery("");
        setSnapshotData(null);
        setReviewLength(0);
        event.target.reset();
      } else {
        console.error("Error submitting review:", responseBody);
        setShowErrorModal(true);
      }
    } catch (error) {
      setShowErrorModal(true);
      console.error("Error submitting review:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <br></br>
      <h1 className="pageTitles">Submit Review</h1>
      <div className="form-container centered-page register-page">
        <p>
          <strong>Find and Review Brokers:</strong> Enter a broker's MC number
          (excluding the prefix) to initiate a review. The system will populate
          their details for verification and automatically fill out the MC
          Number and Broker Name fields. Vote on your overall experience with
          the broker before submitting.
        </p>
        <br></br>
        <form className="form-container">
          <label className="myform-label"> Search by MC Number or Name</label>
          <div>
            <input
              className="searchBox"
              type="text"
              value={searchQuery}
              onChange={handleInputChange}
              placeholder="Enter MC Number"
            />
            <button onClick={handleSearch} disabled={loading}>
              Search Broker
            </button>
          </div>
        </form>
        <hr></hr>
        <br></br>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {searchQuery &&
          snapshotData &&
          Object.keys(snapshotData).length === 0 && <p>No record found.</p>}
        <div>
          <code>Results will generate below</code>
          {snapshotData && Object.keys(snapshotData).length > 0 && (
            <div className="centered-card">
              <h3 className="title">BROKER CARD</h3>
              <p>
                <span className="cardheader">Legal Name:</span>
                {snapshotData.legal_name}
              </p>
              <p>
                <span className="cardheader">Business Name: </span>{" "}
                {snapshotData.dba_name || "N/A"}
              </p>
              <p>
                <span className="cardheader">Physical Address: </span>{" "}
                {snapshotData.physical_address || "N/A"}
              </p>
              <p>
                <span className="cardheader">MC Number: </span>{" "}
                {snapshotData.mc_mx_ff_numbers || "N/A"}
              </p>
              <p>
                <span className="cardheader">Phone: </span>{" "}
                {snapshotData.phone || "N/A"}
              </p>
            </div>
          )}
        </div>
        <div className="formDiv">
          <form onSubmit={handleSubmit}>
            <br />
            <label>Broker Name</label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Broker Name"
              required
            />
            <label>MC Number</label>
            <input
              id="number"
              type="text"
              name="number"
              placeholder="Broker MC Number"
              required
            />
            <br />
            <label>Your Review</label>
            <textarea
              maxLength="1000"
              className="textBox"
              type="text"
              name="review"
              placeholder="Review"
              required
              onChange={handleReviewChange}
            />
            <div>Characters: {reviewLength}/1000</div>
            <br />
            <br />
            <div className="">
              How was your overall experience with{" "}
              {snapshotData && snapshotData.legal_name}?<br></br>
              <label className="radioLabel">
                <input
                  type="radio"
                  id="neutral"
                  value="neutral"
                  checked={feedback === "neutral"}
                  onChange={() => setFeedback("neutral")}
                />{" "}
                Neutral
              </label>
              <label className="radioLabel">
                <input
                  type="radio"
                  id="positive"
                  value="positive"
                  checked={feedback === "positive"}
                  onChange={() => setFeedback("positive")}
                />{" "}
                Positive
              </label>
              <label className="radioLabel">
                <input
                  type="radio"
                  id="negative"
                  value="negative"
                  checked={feedback === "negative"}
                  onChange={() => setFeedback("negative")}
                />{" "}
                Negative
              </label>
            </div>
            <button type="submit">SEND</button>
          </form>
        </div>
      </div>

      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your review was submitted successfully.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSuccessModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error!</Modal.Title>
        </Modal.Header>
        <Modal.Body>There was an error submitting your review.</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReviewPage;
