//combinedComponents
import React, { useState, useEffect } from "react";
import DisplayDataPage from "./displayData";
import PieChartDisplay from "./chart";
import { useUser } from "../context/UserContext";
import { useNavigate } from "react-router-dom";

const CombinedComponents = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      // If no user is found, redirect to the sign-in page
      navigate("/signInRequired");
    } else if (user.status !== "active") {
      // If the user is not active, redirect to the subscription page
      navigate("/subscriptionRequired");
    }
  }, [user, navigate]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  if (!user || user.status !== "active") {
    return null; // Optionally render a loading spinner or something else here
  }

  return (
    <div
      className="ScrollableContainer"
      style={{ maxHeight: "500px", overflowY: "auto" }}
    >
      <br />
      <h1 className="pageTitles">Index Of Reviews</h1>
      <i className="centered-text">
        Search in field below using MC Number or Broker Name. <br></br>
        Please don't include the MC prefix when searching by MC Number.
      </i>
      <div className="SearchInputContainer">
        <input
          type="text"
          placeholder="Broker Search"
          value={searchQuery}
          onChange={handleSearchChange}
          className="SearchInput"
        />
      </div>
      <div></div>
      <div className="SideBySideContainer">
        <div className="DisplayDataContainer" style={{ flex: 1 }}>
          <DisplayDataPage searchQuery={searchQuery} />
        </div>
        <div className="PieChartContainer" style={{ flex: 1 }}>
          <PieChartDisplay searchQuery={searchQuery} />
        </div>
      </div>
    </div>
  );
};

export default CombinedComponents;
