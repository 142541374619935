import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const COLORS = ["#FF0400", "#3A980F", "#1E90FF"]; // Colors for negative, positive, and neutral

function PieChartDisplay({ searchQuery }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, [searchQuery]); // Fetch data when searchQuery changes

  const fetchData = async () => {
    try {
      const response = await fetch("https://thebroker411.com:8443/api/Brokers"); //Endpoint
      const data = await response.json();
      setData(
        data.filter(
          (broker) =>
            broker.name === searchQuery || String(broker.number) === searchQuery
        )
      ); // Filter data based on exact match for name or number
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Calculate total reviews for each sentiment
  const totalReviews = data
    ? data.reduce(
        (acc, broker) =>
          acc +
          (broker.negative || 0) +
          (broker.positive || 0) +
          (broker.neutral || 0),
        0
      )
    : 0;

  // Calculate percentages for each sentiment
  const pieChartData = [
    {
      name: "Negative",
      value: data
        ? data.reduce((acc, broker) => acc + (broker.negative || 0), 0)
        : 0,
    },
    {
      name: "Positive",
      value: data
        ? data.reduce((acc, broker) => acc + (broker.positive || 0), 0)
        : 0,
    },
    {
      name: "Neutral",
      value: data
        ? data.reduce((acc, broker) => acc + (broker.neutral || 0), 0)
        : 0,
    },
  ];

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={pieChartData}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            label={({ name, percent }) =>
              `${name} ${(percent * 100).toFixed(0)}%`
            }
          >
            {pieChartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {searchQuery && data && data.length > 0 && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <p>
            <strong>Broker Name:</strong> {data[0]?.name} |{" "}
            <strong>MC Number:</strong> {data[0]?.number}
          </p>
        </div>
      )}
    </div>
  );
}

export default PieChartDisplay;
