import React from "react";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import "./faq.css";

function FAQ() {
  return (
    <div>
      <br></br>
      <h1 className="pageTitles">Frequently Asked Questions</h1>
      <MDBContainer className="mt-5 FAQ" style={{ maxWidth: "1000px" }}>
        <MDBAccordion
          alwaysOpen
          initialActive={1}
          style={{ backgroundColor: "rgba(246, 246, 246, 255)" }}
        >
          <MDBAccordionItem
            collapseId={1}
            headerTitle="How many reviews can each user submit?"
            className="customAccordionItem"
          >
            <strong>You can submit unlimited number of reviews.</strong> There
            are no limits to the reviews you can submit. However, there is a
            limit to the number of characters you can add per review.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={2}
            headerTitle="What kinds of reviews can I submit"
            className="customAccordionItem"
          >
            <strong>We welcome reviews of any type.</strong> Both your positive
            and negative experiences can be added as review for our communuty to
            benifit from.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={3}
            headerTitle="What is the purpose of the overall experience selections when submitting a review"
            className="customAccordionItem"
          >
            <strong>
              These selections help users quickly understand the overall
              sentiment towards each broker.
            </strong>{" "}
            On the Index of Reviews page, you'll see a pie chart for each
            broker, where each slice represents the percentage of votes
            submitted for Positive, Negative, and Neutral experiences.
          </MDBAccordionItem>

          <MDBAccordionItem
            collapseId={3}
            headerTitle="Why do I get an internal server error?"
            className="customAccordionItem"
          >
            <strong>
              It seems you're encountering this issue due to using the HTTP
              protocol.
            </strong>{" "}
            To access our servers securely and resolve the error, please ensure
            you're typing "HTTPS" instead of "HTTP" at the beginning of our
            website URL. HTTPS stands for Hypertext Transfer Protocol Secure,
            which provides a more secure connection.
          </MDBAccordionItem>
        </MDBAccordion>
      </MDBContainer>
      <br></br>

      <p>
        <p className="centeredtext">
          Have a question that has not been answered? Please get in touch with
          us: <a href="mailto:info@thebroker411.com">info@thebroker411.com</a>
        </p>
      </p>

      <br></br>
      <br></br>

      <br></br>
    </div>
  );
}
export default FAQ;
