import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { ProtectedRoute, UserRoutes } from "./components/protectedRoutes";

import IndexOfReviewPage from "./components/indexOfReviews";
import * as React from "react";
import { useUser } from "./context/UserContext";

import HomePage from "./components/home";

import RedirectIfAuthenticated from "./components/redirect";
import Logout from "./components/Logout";
import SignInRequired from "./components/signInRequired";
import SubscriptionRequired from "./components/subscriptionRequired";
import Navbarsx2 from "./utils/navbar";
import ReviewPage from "./components/reviews";
import DisplayDataPage from "./components/displayData";
import { InactiveUserRoute } from "./components/protectedRoutes";
// import Glossary from "./components/glossary";
import NewFooter from "./utils/newFooter";
import Overview from "./components/overview";
import FAQ from "./components/faq";
import FourOfour from "./components/fourOfour";
// import CheckoutForm from "./components/CheckoutForm";
import Register from "./components/register";
import Login from "./components/login";
import AboutUs from "./components/aboutUs";
import ResetPassword from "./components/resetPassword";
import PieChartDisplay from "./components/chart";
import ParentComponent from "./components/searchComponent";
import MainPage from "./components/mainpage";
import CombinedComponents from "./components/combinedComponents";
import ContactForm from "./components/contactUs";
import SendOTP from "./components/sendOTP";
import VerifyOTP from "./components/verifyOTP";
import UserProfile from "./components/userprofile";
import Profile from "./components/userprofile";

import PrivacyPolicy from "./legalTexts/PrivacyPolicy";
import TermsAndConditions from "./legalTexts/TermsAndConditions";
import { AuthProvider } from "./context/AuthContext";

import { UserProvider } from "./context/UserContext";

// import { AuthProvider } from "./context/AuthProvider";
const Glossary = React.lazy(() => import("./components/glossary"));

function App() {
  return (
    <React.StrictMode>
      <Router>
        <Navbarsx2 />

        <UserProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/subscriptionRequired"
              element={<SubscriptionRequired />}
            />
            <Route path="/signInRequired" element={<SignInRequired />} />
            <Route
              path="/SignUp"
              element={
                <RedirectIfAuthenticated>
                  <Register />
                </RedirectIfAuthenticated>
              }
            />{" "}
            <Route
              path="/Login"
              element={
                <RedirectIfAuthenticated>
                  <Login />
                </RedirectIfAuthenticated>
              }
            />{" "}
            <Route path="/Glossary" element={<Glossary />} />
            <Route path="/FAQ" element={<FAQ />} />
            <Route path="/Overview" element={<Overview />} />
            {/* <Route path="/AboutUs" element={<AboutUs />} /> */}
            <Route path="Main" element={<MainPage />} />
            {/*I want this to be available to those who sign up and create account*/}
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="terms-and-conditions"
              element={<TermsAndConditions />}
            />
            {/* <Route path="/IndexOfReviews" element={<IndexOfReviewPage />} /> */}
            <Route path="/ReviewPage" element={<ReviewPage />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/PasswordReset" element={<ResetPassword />} />
            <Route path="/ContactUs" element={<ContactForm />} />
            <Route path="/AboutUs" element={<AboutUs />} />{" "}
            <Route path="/profile" element={<Profile />} />{" "}
            <Route path="*" element={<FourOfour />} />
            <Route path="/Chart" element={<PieChartDisplay />} />
            <Route
              path="/IndexOfReviews"
              element={
                <ProtectedRoute>
                  <CombinedComponents />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Reviews"
              element={
                <ProtectedRoute>
                  <ReviewPage />
                </ProtectedRoute>
              }
            />
            <Route path="/Display" element={<DisplayDataPage />} />
            <Route path="/ForgotPassword" element={<SendOTP />} />
            <Route path="/verify-otp" element={<VerifyOTP />} />
          </Routes>
          <div className="footer-top"></div>
          <NewFooter />
        </UserProvider>
      </Router>
    </React.StrictMode>
  );
}

export default App;
