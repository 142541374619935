import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

const Logout = () => {
  const { handleLogout } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    handleLogout(); // Call the logout function from the context
    navigate("/Login"); // Redirect to login page after logging out
  }, [handleLogout, navigate]);

  return <div>Logging out...</div>;
};

export default Logout;
