//client/src/context/UserContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { axiosInstance } from "../components/api/axios"; // Use the custom instance

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user/profile", {
          withCredentials: true,
        });
        setUser(response.data.user);
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  // Define handleLogout without a backend call
  const handleLogout = () => {
    // Clear the user state
    setUser(null);

    // Remove the token from localStorage, sessionStorage, or cookies
    localStorage.removeItem("token"); // Assuming you store the JWT token in localStorage
    document.cookie = "token=; Max-Age=0"; // Clear the token cookie if using cookies
  };

  return (
    <UserContext.Provider value={{ user, setUser, loading, handleLogout }}>
      {children}
    </UserContext.Provider>
  );
};
