// //ProtectedRoutes.js

// import React from "react";
// import { Navigate } from "react-router-dom";

// import { useUser } from "../context/UserContext";

// const ProtectedRoute = ({ children }) => {
//   const { user } = useUser();
//   console.log("User in ProtectedRoute:", user); // Debugging

//   if (!user || user.status !== "active") {
//     return <Navigate to="/signInRequired" />;
//   }

//   return children;
// };

// const UserRoutes = ({ children }) => {
//   const { user } = useUser();

//   if (!user || user.status !== "inactive") {
//     return <Navigate to="/subscriptionRequired" />;
//   }

//   return children;
// };

// export { ProtectedRoute, UserRoutes };

// // export default ProtectedRoute;
//client/components/ProtectedRoutes.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

// Protect pages for only active users

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useUser();

  if (loading) {
    return <div>Loading...</div>;
  }
  if (!user) {
    return <Navigate to="/signInRequired" />;
  }

  // Inactive users are redirected to "/subscriptionRequired"
  if (user.status === "inactive") {
    return <Navigate to="/subscriptionRequired" />;
  }

  // Active users have access to all pages
  return children;
};

//Token/Session Issues: Ensure that the authentication token is correctly stored and sent with every request.
//Since you're using cookies, make sure the backend is setting the httpOnly and secure flags for your cookies, especially if you're running the app over HTTPS.

// Redirect to subscription page if the user is inactive
const InactiveUserRoute = ({ children }) => {
  const { user, loading } = useUser();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (user?.status === "inactive") {
    return children;
  }
  return <Navigate to="/subscriptionRequired" />;
};

export { ProtectedRoute, InactiveUserRoute };
