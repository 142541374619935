// import axios from "axios";

// export default axios.create({
//   baseURL: "https://thebroker411.com:8443/api/",
//   headers: {
//     "Content-Type": "application/json",
//   },
//   withCredentials: true,
// });
// export default axios;
// import axios from "axios";

// const axios = axios.create({
//   baseURL: "http://162.240.167.99/api/",
//   headers: {
//     "Content-Type": "application/json",
//     Accept: "application/json",
//   },
//   withCredentials: true,
// });

//export default axios;
import axios from "axios";

// Create an axios instance
const instance = axios.create({
  baseURL: "https://thebroker411.com:8443/api/",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Export the axios instance without interceptors
export const axiosInstance = instance;

// Add the interceptor to your instance but do not export it
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Default export as axios instance without interceptors
export default axios;
