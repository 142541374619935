//userprofile.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./api/axios";
import { useUser } from "../context/UserContext";

const Profile = () => {
  const { user, setUser, token } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (!token) {
          console.error("No token found");
          navigate("/login");
          return;
        }

        console.log("Token used for request:", token);

        const response = await axios.get("/api/user/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          setUser(response.data);
        }
      } catch (error) {
        console.error("Error fetching profile", error);
        navigate("/login");
      }
    };

    if (!user) {
      fetchUserProfile();
    }
  }, [user, setUser, navigate, token]);

  return user ? (
    <div>
      <h1 className="pageTitles">Profile</h1>
      <div className="user-profile">
        <p>Name: {user.user}</p>
        <p>Email: {user.userEmail}</p>
        <p>MC Number: {user.mcnumber}</p>

        <p>Status: {user.status}</p>
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default Profile;

// import React from "react";
// import { useAuth } from "../context/AuthContext";

// const Profile = () => {
//   const { user } = useAuth();

//   return (
//     <div>
//       <br></br>
//       <h1 className="pageTitles">Profile</h1>
//       <div className="user-profile">
//         <p className="user-name">Carrier Company:</p>
//         <p className="user-email">{user.user}</p>
//         <p className="user-name">Email: </p>{" "}
//         <p className="user-email">{user.userEmail}</p>
//         <p className="user-name"> MC Number:</p>{" "}
//         <p className="user-email"> {user.mcnumber}</p>
//         <p className="user-name">Status:</p>{" "}
//         <p className="user-email"> {user.status}</p>
//       </div>{" "}
//     </div>
//   );
// };

// export default Profile;
