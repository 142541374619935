import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

const RedirectIfAuthenticated = ({ children }) => {
  const { user } = useUser();

  // If the user is logged in, redirect them to a different page
  if (user) {
    return <Navigate to="/profile" />; // Redirect to profile or any desired page
  }

  // If not logged in, render the children (login or sign-up page)
  return children;
};

export default RedirectIfAuthenticated;
